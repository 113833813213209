import { Box, Container, Typography } from '@mui/material'
import React from 'react'
import Header from '../components/layouts/Header'
import Footer from '../components/layouts/Footer'
import Updates from '../components/modules/Updates'
import ManualServerStatus from '../components/modules/ManualServerStatus'

const App = (props) => {
  return (
    <Box>
      <Header />
      <Container maxWidth="md">
        <Typography sx={{mt:2.5,}} fontWeight={600} variant="h4" gutterBottom>Status</Typography>
        <Updates />
        <ManualServerStatus />
      </Container>
      <Footer />
    </Box>
  )
}

export default App