import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import SuccessIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorIcon from '@mui/icons-material/HighlightOff';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, IconButton, ListItemSecondaryAction, Paper, Typography } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Apple, Google } from '@mui/icons-material';


const ManualServerStatus = () => {

  return (
    <Box sx={{ mt: 2.5 }}>
      <Paper>
        <List>
          <ListItem>
            <ListItemIcon>
              <SuccessIcon color="success" />
            </ListItemIcon>
            <ListItemText
              primary={<Typography variant="body1" fontWeight="bold">www.cadsoft.com</Typography>}
              secondary="All systems operational"
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <SuccessIcon color="success" />
            </ListItemIcon>
            <ListItemText
              primary={<Typography variant="body1" fontWeight="bold">www.homeviewvr.com</Typography>}
              secondary="All systems operational"
            />
            <ListItemSecondaryAction>
              <IconButton href="https://apps.apple.com/ca/app/homeviewvr/id1201255972">
                <Apple sx={{color: "black"}} />
              </IconButton>
              <IconButton href="https://play.google.com/store/apps/details?id=com.cadsoft.google.cardboard&pcampaignid=web_share">
                <Google sx={{color: "black"}} />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <SuccessIcon color="success" />
            </ListItemIcon>
            <ListItemText
              primary={<Typography variant="body1" fontWeight="bold">www.personalarch.com</Typography>}
              secondary="All systems operational"
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <SuccessIcon color="success" />
            </ListItemIcon>
            <ListItemText
              primary={<Typography variant="body1" fontWeight="bold">designer.personalarch.com</Typography>}
              secondary="All systems operational"
            />
            <ListItemSecondaryAction>
              <IconButton size="small" href="https://designer.personalarch.com/home">
                <ExitToAppIcon sx={{color: "black"}} />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </Paper>
    </Box>
  );
};

export default ManualServerStatus;
