import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Box } from '@mui/material';

const AppHeader = () => {
  return (
    <AppBar position="static">
      <Toolbar>
        <Box component="img" src='/assets/logo-cadsoft.svg' width="300px" height="45px" alt="Logo" />
      </Toolbar>
    </AppBar>
  );
};

export default AppHeader;
