import { Box, Paper, Typography } from '@mui/material'
import React from 'react'

const Updates = (props) => {
  return (
    <Box sx={{mt:1,}}>
      <Paper sx={{p:2.5,}}>
        <Typography fontWeight="bold" variant="h6">Updates</Typography>
        <Typography>
          We recently experienced an issue with one of our web hosting providers. All of our websites should now be online.
        </Typography>
      </Paper>
    </Box>
  )
}

export default Updates