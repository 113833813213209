import React from 'react'
import '../../../package.json'
import { Box } from '@mui/material'

const Footer = (props) => {
  return (
    <Box>
    </Box>
  )
}

export default Footer